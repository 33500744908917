// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".theme-alert-1y45JgBa {\n  margin-bottom: 20px;\n}\n", "",{"version":3,"sources":["/home/circleci/project/src/components/update-organisation/components/create-budget-period/theme.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB","file":"theme.css","sourcesContent":[".alert {\n  margin-bottom: 20px;\n}\n"]}]);
// Exports
exports.locals = {
	"alert": "theme-alert-1y45JgBa"
};
module.exports = exports;
