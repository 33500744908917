// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".theme-dialog-2ZYAVCoT {\n  margin-top: 70px;\n  max-height: 75%;\n  overflow-y: scroll;\n}\n", "",{"version":3,"sources":["/home/circleci/project/src/components/update-expert-dialog/theme.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB","file":"theme.css","sourcesContent":[".dialog {\n  margin-top: 70px;\n  max-height: 75%;\n  overflow-y: scroll;\n}\n"]}]);
// Exports
exports.locals = {
	"dialog": "theme-dialog-2ZYAVCoT"
};
module.exports = exports;
