// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".theme-alert-2HpR4BQh {\n  margin: 20px 0;\n}\n\n.theme-paginationContainer-21hrfcu0 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: end;\n  margin: 1em 0;\n}\n", "",{"version":3,"sources":["/home/circleci/project/src/components/update-organisation/theme.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,oBAAa;EAAb,oBAAa;EAAb,aAAa;EACb,qBAAoB;MAApB,kBAAoB;UAApB,oBAAoB;EACpB,aAAa;AACf","file":"theme.css","sourcesContent":[".alert {\n  margin: 20px 0;\n}\n\n.paginationContainer {\n  display: flex;\n  justify-content: end;\n  margin: 1em 0;\n}\n"]}]);
// Exports
exports.locals = {
	"alert": "theme-alert-2HpR4BQh",
	"paginationContainer": "theme-paginationContainer-21hrfcu0"
};
module.exports = exports;
