// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-supplierMessage-26-8Sekv {\n  color: #43ab95;\n  font-size: 12px;\n  line-height: 20px;\n  font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n}\n\n.styles-codeClaim-1XPhuq2L {\n  padding: 20px 0;\n}\n\n.styles-helpertext-1IgsRWAh {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n\n  margin-bottom: 24px;\n  margin-bottom: 1.5rem;\n}\n\n.styles-helpertext-1IgsRWAh > div {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n\n.styles-preview-1DVaTdnX {\n  font-family: \"Europa\", \"Helvetica\", \"Arial\", sans-serif;\n  background-color: #f7f9fb;\n  padding: 16px;\n  padding: 1rem;\n  text-align: left;\n}\n", "",{"version":3,"sources":["/home/circleci/project/src/components/create-online-course-form/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,uDAAuD;AACzD;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oBAAa;EAAb,oBAAa;EAAb,aAAa;EACb,4BAAsB;EAAtB,6BAAsB;MAAtB,0BAAsB;UAAtB,sBAAsB;;EAEtB,mBAAqB;EAArB,qBAAqB;AACvB;;AAEA;EACE,mBAAO;MAAP,WAAO;UAAP,OAAO;AACT;;AAEA;EACE,uDAAuD;EACvD,yBAAyB;EACzB,aAAa;EAAb,aAAa;EACb,gBAAgB;AAClB","file":"styles.css","sourcesContent":[".supplierMessage {\n  color: #43ab95;\n  font-size: 12px;\n  line-height: 20px;\n  font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n}\n\n.codeClaim {\n  padding: 20px 0;\n}\n\n.helpertext {\n  display: flex;\n  flex-direction: column;\n\n  margin-bottom: 1.5rem;\n}\n\n.helpertext > div {\n  flex: 1;\n}\n\n.preview {\n  font-family: \"Europa\", \"Helvetica\", \"Arial\", sans-serif;\n  background-color: #f7f9fb;\n  padding: 1rem;\n  text-align: left;\n}\n"]}]);
// Exports
exports.locals = {
	"supplierMessage": "styles-supplierMessage-26-8Sekv",
	"codeClaim": "styles-codeClaim-1XPhuq2L",
	"helpertext": "styles-helpertext-1IgsRWAh",
	"preview": "styles-preview-1DVaTdnX"
};
module.exports = exports;
