// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-row-wlLpj5Am {\n  margin: 16px auto;\n  margin: 1rem auto;\n}\n\n.styles-warning-2BfYQ00g {\n  background-color: gold;\n  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2),\n    0 1px 5px 0 rgba(0, 0, 0, .12);\n          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2),\n    0 1px 5px 0 rgba(0, 0, 0, .12);\n  color: black;\n  font-weight: bold;\n}\n\n.styles-bold-37NOV1jG {\n  font-weight: bold;\n}\n\n.styles-action-VEvUOgb2 {\n  margin-right: 16px;\n  margin-right: 1rem;\n}\n", "",{"version":3,"sources":["/home/circleci/project/src/components/content-region-mismatch/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EAAjB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB;kCACiC;UADjC;kCACiC;EACjC,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAAlB,kBAAkB;AACpB","file":"styles.css","sourcesContent":[".row {\n  margin: 1rem auto;\n}\n\n.warning {\n  background-color: gold;\n  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),\n    0 1px 5px 0 rgba(0, 0, 0, 0.12);\n  color: black;\n  font-weight: bold;\n}\n\n.bold {\n  font-weight: bold;\n}\n\n.action {\n  margin-right: 1rem;\n}\n"]}]);
// Exports
exports.locals = {
	"row": "styles-row-wlLpj5Am",
	"warning": "styles-warning-2BfYQ00g",
	"bold": "styles-bold-37NOV1jG",
	"action": "styles-action-VEvUOgb2"
};
module.exports = exports;
