// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".theme-apiContainer-1DlMuxYn {\n  margin-top: 30px;\n}\n\n.theme-copyContainer-14ocz_Og {\n  margin-top: 24px;\n}\n\n.theme-button-1Ce0Wkzm {\n  width: 540px;\n}\n\n.theme-copyIcon-3Wx8B6-k {\n  margin-bottom: 60px;\n}\n\n.theme-textarea-2x6du-H1 {\n  border: 2px solid #43ab95;\n  word-break: break-all;\n  height: 60px;\n}\n\n.theme-textarea-2x6du-H1:hover {\n  border-color: #74c4b5;\n}\n", "",{"version":3,"sources":["/home/circleci/project/src/components/show-api-key/theme.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB","file":"theme.css","sourcesContent":[".apiContainer {\n  margin-top: 30px;\n}\n\n.copyContainer {\n  margin-top: 24px;\n}\n\n.button {\n  width: 540px;\n}\n\n.copyIcon {\n  margin-bottom: 60px;\n}\n\n.textarea {\n  border: 2px solid #43ab95;\n  word-break: break-all;\n  height: 60px;\n}\n\n.textarea:hover {\n  border-color: #74c4b5;\n}\n"]}]);
// Exports
exports.locals = {
	"apiContainer": "theme-apiContainer-1DlMuxYn",
	"copyContainer": "theme-copyContainer-14ocz_Og",
	"button": "theme-button-1Ce0Wkzm",
	"copyIcon": "theme-copyIcon-3Wx8B6-k",
	"textarea": "theme-textarea-2x6du-H1"
};
module.exports = exports;
