// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".theme-container-1vf-MUNL {\n  margin-bottom: 40px;\n}\n\n.theme-tableHeader-33zn6kzS {\n  font-weight: 600;\n  margin-bottom: 12px;\n}\n\n.theme-content-nWdHZkjB {\n  overflow: scroll;\n  height: 100px;\n}\n", "",{"version":3,"sources":["/home/circleci/project/src/containers/reviews/theme.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf","file":"theme.css","sourcesContent":[".container {\n  margin-bottom: 40px;\n}\n\n.tableHeader {\n  font-weight: 600;\n  margin-bottom: 12px;\n}\n\n.content {\n  overflow: scroll;\n  height: 100px;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "theme-container-1vf-MUNL",
	"tableHeader": "theme-tableHeader-33zn6kzS",
	"content": "theme-content-nWdHZkjB"
};
module.exports = exports;
